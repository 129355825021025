/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import HTMuseumData from 'views/DemoModels/GeoTour3DData';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const HTMuseumPage = () => {
  return <WithLayout component={HTMuseumData} layout={Main}  modelID={3}/>;
};

export default HTMuseumPage;
